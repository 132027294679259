import { Layers } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Box, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { differenceInMilliseconds } from "date-fns";
import format from "date-fns/format";
import isPast from "date-fns/isPast";
import React, {useEffect, useMemo, useRef, useState} from "react";

import { gutterWidth, minTimeSlotHeight, shiftSpacing } from "./constants";
import { TimeSlot } from "./styled-components";
import { calculateIndicatorPosition } from "./utils";

const TimeSlotContainer = ({
  openCreateNewShiftDialog,
  canCreateOrEditShift,
  dayIndex,
  todayPosition,
  hourIndex,
  day,
  timeSlotHeight,
  allPassingShifts,
  openLayerPopover,
  selectedHourDateInfo,
  ...props
}: {
  openCreateNewShiftDialog: any;
  canCreateOrEditShift: boolean;
  dayIndex: number;
  todayPosition: number;
  hourIndex: number;
  day: Date;
  timeSlotHeight: number;
  allPassingShifts: any;
  openLayerPopover: any;
  selectedHourDateInfo: any;
} & React.ComponentProps<typeof Grid>) => {
  const [currentTimeHourIndex, setCurrentTimeHourIndex] = useState(-1);
  const timeoutIdRef = useRef<any>(null); // Use a ref to store the timeout ID
  const isFirstRender = useRef(true); // Track the first render

  // One hour string based on hourIndex (0-23) like "4:00 AM - 5:00 AM"
  const timeDateInfo = useMemo(() => {
    const startHour = hourIndex;
    const endHour = hourIndex + 1;
    // Change military time to standard time
    let startHourString = startHour % 12 === 0 ? "12" : `${startHour % 12}`;
    let endHourString = endHour % 12 === 0 ? "12" : `${endHour % 12}`;
    // Add AM and PM
    if (startHour < 12) {
      startHourString = startHourString + " AM";
    } else {
      startHourString = startHourString + " PM";
    }
    if (endHour < 12) {
      endHourString = endHourString + " AM";
    } else {
      endHourString = endHourString + " PM";
    }
    return {
      hourText: `${startHourString} - ${endHourString}`,
      dayText: format(day, "EEE") ?? "-",
      dateText: format(day, "dd") ?? "-",
    };
  }, [hourIndex, day]);

  const [isHovered, setIsHovered] = useState(false);

  const isInPast = useMemo(() => isPast(day), [day]);
  const isToday = useMemo(
    () => todayPosition === dayIndex,
    [todayPosition, dayIndex]
  );
  const isPastHour = useMemo(
    () => isInPast && hourIndex - 1 < new Date().getHours(),
    [isInPast, hourIndex]
  );

  useEffect(() => {

    const calculateIndex = () => {
      const newCurrentTimeHourIndex = Math.floor(
          calculateIndicatorPosition(new Date(), timeSlotHeight) / timeSlotHeight
      );
      if (currentTimeHourIndex !== newCurrentTimeHourIndex) {
        setCurrentTimeHourIndex(newCurrentTimeHourIndex);
      }
    };

    const scheduleNextUpdate = () => {
      const timeUntilTarget = differenceInMilliseconds(
          new Date(day).setHours(hourIndex, 0, 0, 0),
          new Date()
      );

      if (timeUntilTarget > 0) {
        // Clear any existing timeout before setting a new one
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
        }

        timeoutIdRef.current = setTimeout(() => {
          calculateIndex();
          timeoutIdRef.current = null; // Clear the ref after the timeout fires
        }, timeUntilTarget);
      } else {
        calculateIndex();
      }
    };
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (
      currentTimeHourIndex === -1 &&
      timeoutIdRef.current === null &&
      isToday &&
      !isPastHour
    ) {
      scheduleNextUpdate();
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        // Page is now hidden
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
          timeoutIdRef.current = null;
        }
      } else {
        // Page is now visible
        if (
            currentTimeHourIndex === -1 &&
            timeoutIdRef.current === null &&
            isToday &&
            !isPastHour
        ) {
          scheduleNextUpdate(); // Restart your timeout logic
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => { // Cleanup function
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null; // Good practice to also clear the ref
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimeHourIndex, timeSlotHeight, day, isToday, isPastHour, hourIndex]);

  const dateWithHour = useMemo(
    () => new Date(day).setHours(hourIndex),
    [day, hourIndex]
  );

  const handleClick = () => {
    if (canCreateOrEditShift) {
      openCreateNewShiftDialog(new Date(dateWithHour));
    }
  };

  let isPropsEmpty = true;

  // Check if props is empty or not by evaluating props contain children or not
  if (Array.isArray(props.children) ? props.children.length > 0 : false) {
    isPropsEmpty = false;
  }

  // Check if allPassingShifts is empty or not by evaluating allPassingShifts contain children or not
  isPropsEmpty = Array.isArray(allPassingShifts)
    ? allPassingShifts.length === 0
    : isPropsEmpty;
  let canShowLayerIcon = false;

  const isClicked =
    selectedHourDateInfo?.hourIndex === hourIndex &&
    selectedHourDateInfo?.dayIndex === dayIndex;
  if (isClicked) {
    // Show layer icon when clicked
    canShowLayerIcon = true;
  }

  const canShowAddIcon =
    isHovered &&
    !(isInPast && !isToday) &&
    !(isToday && (isPastHour|| currentTimeHourIndex >= hourIndex));

  // Display layer icon if shifts are eclipsing each other under 10min
  if (!canShowLayerIcon) {
    if (Array.isArray(allPassingShifts) && allPassingShifts.length >= 2) {
      const minMargin = 50;
      const minWidth = shiftSpacing * 2;
      const minDifferenceInMinutes = 9; // if shifts difference is 9 min or less then display layer icon
      allPassingShifts.forEach((shift: any, index: number) => {
        if (index + 1 < allPassingShifts.length) {
          const currentShift = shift;
          const nextShift = allPassingShifts[index + 1];
          const currentShiftStartTime = new Date(currentShift.startDateTime);
          const nextShiftStartTime = new Date(nextShift.startDateTime);
          const differenceInMinutes =
            (nextShiftStartTime.getTime() - currentShiftStartTime.getTime()) /
            60000;
          if (
            currentShift.changedMarginOfShift > minMargin &&
            currentShift.shiftWidthPercent <= minWidth &&
            nextShift.changedMarginOfShift > minMargin &&
            nextShift.shiftWidthPercent <= minWidth &&
            differenceInMinutes <= minDifferenceInMinutes
          ) {
            canShowLayerIcon = true;
          }
        }
      });
    }
  }

  if ((isInPast && !isToday) || (isToday && (isPastHour || currentTimeHourIndex >= hourIndex)) || !isPropsEmpty) {
    return (
      <TimeSlot
        {...props}
        height={timeSlotHeight}
        boxShadow={
          selectedHourDateInfo?.hourIndex === hourIndex &&
          selectedHourDateInfo.dayIndex === dayIndex
            ? `inset 0 0 0 ${timeSlotHeight / 2}px #2F4D8029`
            : "none"
        }
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor:
              (isToday && (isPastHour || currentTimeHourIndex >= hourIndex))
                ? "#2F4D8011"
                : "transparent",
          }}
        >
          {props.children}
          {!isPropsEmpty ? (
            <Box
              sx={{
                position: "absolute",
                right: 0,
                bottom: 0,
                width: `${gutterWidth}px`,
                height: `${timeSlotHeight}px`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor:
                    !isToday && isInPast ? "rgba(0,0,0,0.02)" : "#2F4D8005",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                  marginTop: "1px",
                  marginBottom: "1px",
                }}
              >
                {canShowAddIcon && (
                  <IconButton
                    aria-label="add"
                    size="small"
                    sx={{
                      width: `${gutterWidth}px`,
                      height: `${(minTimeSlotHeight - 2) / 2}px`,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleClick}
                  >
                    <AddIcon
                      sx={{
                        fontSize: `${(gutterWidth * 4.5) / 5}px`,
                        color: "#2F4D80",
                      }}
                    />
                  </IconButton>
                )}
                {/* Spacer */}
                {!canShowAddIcon && (
                  <Box
                    sx={{
                      width: `${gutterWidth}px`,
                      height: `${(minTimeSlotHeight - 2) / 2}px`,
                    }}
                  />
                )}
                {((isHovered && allPassingShifts.length >= 2) ||
                  canShowLayerIcon) && (
                  <Tooltip title={`${allPassingShifts.length} Stacked Shifts`}>
                    <IconButton
                      aria-label="view-shift"
                      size="small"
                      sx={{
                        width: `${gutterWidth}px`,
                        height: `${(minTimeSlotHeight - 2) / 2}px`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={(e) =>
                        openLayerPopover(
                          e,
                          allPassingShifts,
                          hourIndex,
                          dayIndex,
                          timeDateInfo
                        )
                      }
                    >
                      <Layers
                        sx={{
                          fontSize: `${(gutterWidth * 7) / 9}px`,
                          color: "#2F4D80",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                position: "absolute",
                right: 0,
                bottom: 0,
                width: `${gutterWidth}px`,
                height: `${timeSlotHeight}px`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor:
                    !isToday && isInPast ? "rgba(0,0,0,0.02)" : "#2F4D8005",
                  height: "100%",
                  width: "100%",
                  marginTop: "1px",
                  marginBottom: "1px",
                }}
              />
            </Box>
          )}
        </Box>
      </TimeSlot>
    );
  } else {
    return (
      <TimeSlot
        {...props}
        height={timeSlotHeight}
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor:
              (isToday && (isPastHour || currentTimeHourIndex >= hourIndex))
                ? "#2F4D8011"
                : isToday
                ? "#2F4D8B03"
                : "#fff",
          }}
        >
          {isHovered ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                cursor: "pointer",
                backgroundColor: isHovered ? "rgb(251,251,253)" : "#fff",
                flexDirection: "row",
              }}
            >
              <AddIcon
                sx={{
                  fontSize: "20px",
                  textTransform: "uppercase",
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  color: "#2F4D80",
                }}
              />
              <Typography
                sx={{
                  marginLeft: "5px",
                  fontSize: "14px",
                  textTransform: "uppercase",
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  color: "#2F4D80",
                }}
              >
                Shift
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: `${gutterWidth}px`,
            height: `${timeSlotHeight}px`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: isHovered ? "transparent" : "rgb(251,251,253)",
              height: "100%",
              width: "100%",
              marginTop: "1px",
              marginBottom: "1px",
            }}
          />
        </Box>
      </TimeSlot>
    );
  }
};

export default React.memo(TimeSlotContainer);
