import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
	Checkbox,
	Chip,
	CircularProgress,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	Skeleton,
	Snackbar,
	Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import useGatAdminConfigData from "../../../../../../../../ts/components/gat-admin/controllers/use-gat-admin-config-data";
import {
	resetUpdateAllowedWorkCentersForOverlapShifts,
	updateAllowedWorkCentersForOverlapShifts,
} from "../../../../../../../../ts/components/gat-admin/store/gat-admin-slice";
const AllowedWorkcenters = ({ loading }: { loading: boolean }) => {
	const {
		currentLocationData,
		updateAllowedWorkCentersForOverlapShiftsStatus,
		locationSettingsObj,
		getLocationSettingsStatus,
	} = useGatAdminConfigData();
	const dispatch = useDispatch();
	const [selectedWorkcenters, setSelectedWorkcenters] = useState<string[]>([]);

	const wc = currentLocationData?.workCenters.reduce(
		(acc: { [key: number]: string }, wc: { id: number; name: string }) => {
			acc[wc.id] = wc.name;
			return acc;
		},
		{}
	);
	const locationWorkcenters = currentLocationData?.workCenters;

	const handleWorkcenterChange = (
		event: SelectChangeEvent<typeof selectedWorkcenters>
	) => {
		const value = event.target.value;

		// Handle "Select All" option
		if (value.includes("all")) {
			if (selectedWorkcenters.length === locationWorkcenters?.length) {
				// If all are selected, unselect all
				setSelectedWorkcenters([]);
			} else {
				// Select all workcenters
				setSelectedWorkcenters(
					locationWorkcenters?.map((wc: { id: number }) => wc.id) || []
				);
			}
		} else {
			// Handle individual selections
			setSelectedWorkcenters(value as string[]);
		}
	};

	const handleDelete = (
		event: React.MouseEvent,
		workcenterToDelete: string
	) => {
		event.stopPropagation(); // Prevent the click from reaching the Select
		if (locationSettingsObj["overlappingShiftSingup.Enabled"].value === false) {
			return;
		}
		setSelectedWorkcenters(
			selectedWorkcenters.filter((wc) => wc !== workcenterToDelete)
		);
	};

	const handleUpdate = () => {
		dispatch(
			updateAllowedWorkCentersForOverlapShifts({
				companyId: currentLocationData?.companyId,
				locationId: currentLocationData?.id,
				workcenterIds: selectedWorkcenters,
			})
		);
	};

	useEffect(() => {
		if (locationWorkcenters) {
			setSelectedWorkcenters(
				locationWorkcenters
					?.filter(
						(wc: { isOverlapSignupEnabled: boolean }) =>
							wc.isOverlapSignupEnabled
					)
					.map((wc: { id: number }) => wc.id)
			);
		}
	}, [locationWorkcenters]);

	return (
		<Box
			sx={{
				marginTop: "20px",
				marginBottom: "8px",
				borderRadius: "8px",
				border: 1,
				borderWidth: "1px",
				borderColor: "rgba(0,0,0,0.12)",
				background: "#FFF",
				padding: "16px",
				width: "100%",
			}}
		>
			<Box>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 400,
						fontSize: "16px",
						lineHeight: "24px",
						letterSpacing: "0.15px",
					}}
				>
					Allowed Workcenters
				</Typography>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 400,
						fontSize: "12px",
						lineHeight: "19.92px",
						letterSpacing: "0.4px",
					}}
				>
					Select one or more Workcenters that allow Flexi signups
				</Typography>
			</Box>
			<Box sx={{ display: "flex", gap: 2 }}>
				<FormControl sx={{ width: "90%", marginTop: 2 }}>
					<InputLabel id='workcenter-select-label'>
						Select Workcenters
					</InputLabel>
					{getLocationSettingsStatus === "pending" ? (
						<Skeleton width='100%' height={80} />
					) : (
						<Select
							input={<OutlinedInput label='Select Workcenters' />}
							multiple
							value={selectedWorkcenters}
							onChange={handleWorkcenterChange}
							disabled={
								locationSettingsObj["overlappingShiftSingup.Enabled"].value ===
								false
							}
							renderValue={(selected) => {
								if (selected.length === 0) {
									return "Select Workcenters";
								}
								if (
									selected.length === locationWorkcenters?.length &&
									locationWorkcenters?.length > 5
								) {
									return "All Workcenters Selected";
								}
								return (
									<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
										{selected.map((value) => (
											<Chip
												key={value}
												label={wc[value]}
												deleteIcon={
													<Box
														onMouseDown={(event) => event.stopPropagation()}
														sx={{
															display: "flex",
															alignItems: "center",
															justifyContent: "center",
															width: "20px",
															height: "20px",
															backgroundColor: "#aeaeae",
															borderRadius: "50%",
															cursor:
																locationSettingsObj[
																	"overlappingShiftSingup.Enabled"
																].value === false
																	? "default"
																	: "pointer",
														}}
													>
														<CloseIcon
															sx={{
																color: "#ebebeb",
																fontSize: "16px",
															}}
														/>
													</Box>
												}
												onDelete={(event) => handleDelete(event, value)}
											/>
										))}
									</Box>
								);
							}}
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 300,
									},
								},
							}}
						>
							<MenuItem value='all'>
								<Checkbox
									checked={
										selectedWorkcenters.length === locationWorkcenters?.length
									}
									indeterminate={
										selectedWorkcenters.length > 0 &&
										selectedWorkcenters.length < locationWorkcenters?.length
									}
								/>
								<ListItemText primary='Select All' />
							</MenuItem>
							{locationWorkcenters?.map(
								(workcenter: { id: string; name: string }) => (
									<MenuItem key={workcenter.id} value={workcenter.id}>
										<Checkbox
											checked={selectedWorkcenters.includes(workcenter.id)}
										/>
										<ListItemText primary={workcenter.name} />
									</MenuItem>
								)
							)}
						</Select>
					)}
				</FormControl>
				{getLocationSettingsStatus === "pending" ? (
					<Skeleton width='10%' height={80} sx={{ marginTop: 2 }} />
				) : (
					<LoadingButton
						variant='text'
						color='primary'
						sx={{
							marginTop: 2,
							marginLeft: 2,
							width: "10%",
						}}
						onClick={handleUpdate}
						loading={
							updateAllowedWorkCentersForOverlapShiftsStatus === "pending"
						}
						loadingIndicator={
							<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
								<CircularProgress
									size={16}
									sx={{ color: "rgba(0, 0, 0, 0.38)" }}
								/>
								<Typography>Updating...</Typography>
							</Box>
						}
						disabled={
							getLocationSettingsStatus === "pending" ||
							loading ||
							selectedWorkcenters.length === 0 ||
							JSON.stringify(selectedWorkcenters) ===
								JSON.stringify(
									locationWorkcenters
										?.filter(
											(wc: { isOverlapSignupEnabled: boolean }) =>
												wc.isOverlapSignupEnabled
										)
										.map((wc: { id: string }) => wc.id)
								)
						}
					>
						Update
					</LoadingButton>
				)}
			</Box>
			{updateAllowedWorkCentersForOverlapShiftsStatus === "fulfilled" && (
				<Snackbar
					open={updateAllowedWorkCentersForOverlapShiftsStatus === "fulfilled"}
					autoHideDuration={3000}
					onClose={() =>
						dispatch(resetUpdateAllowedWorkCentersForOverlapShifts())
					}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='success'>
						Workcenters updated successfully
					</MuiAlert>
				</Snackbar>
			)}
		</Box>
	);
};

export default AllowedWorkcenters;
