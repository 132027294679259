/* eslint-disable simple-import-sort/imports */
import Highlight from "../../../../../Highlight";
import { Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useState } from "react";
import { FixedSizeList } from "react-window";
import useTableHeight from "../../../../../../utils/use-table-height";
import PersonOffIcon from "@mui/icons-material/PersonOff";
// import { WorkerInsightModel } from "../../../slices/insight-report-types";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

interface WorkerBackup {
	id: string | undefined;
	name: string;
	primaryJobName: string | undefined;
	locationWorkcenter: string;
}

function InterestedWorkers({
	interestedWorkersList,
}: {
	interestedWorkersList: any;
}) {
	const { height, boxRef } = useTableHeight();
	const [searchTerm, setSearchTerm] = useState("");

	const filteredWorkers = useMemo(() => {
		return interestedWorkersList.filter(
			(worker: any) =>
				(worker.workerName)
					.toLowerCase()
					.includes(searchTerm.toLowerCase()) ||
				(worker.primaryJobName &&
					worker.primaryJobName
						.toLowerCase()
						.includes(searchTerm.toLowerCase())) ||
				(`${worker.locationName} - ${worker.workcenterName}`).toLowerCase().includes(searchTerm.toLowerCase())
		);
	}, [interestedWorkersList, searchTerm]);

	const ListItems: React.FC<{
		item: any;
	}> = ({ item }) => {
		return (
			<Box
				key={`worker-${item.workerId}`}
				sx={{
					display: "flex",
					alignItems: "flex-start",
					justifyContent: "center",
					flexDirection: "column",
					width: "100%",
					border: 1,
					borderLeftWidth: 0,
					borderRightWidth: 0,
					borderTopWidth: 0,
					borderBottomWidth: "1px",
					borderBottomColor: "#F0F0F3",
					height: "87px",
					paddingLeft: "16px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "16px",
						lineHeight: "24px",
						color: "rgba(0, 0, 0, 0.87)",
						fontWeight: 700,
					}}
				>
					<Highlight
						value={item.workerName}
						searchText={searchTerm}
					/>
				</Typography>

				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "14px",
						lineHeight: "20px",
						color: item.primaryJobName
							? "rgba(0, 0, 0, 0.87)"
							: "rgba(0, 0, 0, 0.60)",
					}}
				>
					{item.primaryJobName ? (
						<Highlight
							value={item.primaryJobName || "-"}
							searchText={searchTerm}
						/>
					) : (
						"-"
					)}
				</Typography>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "14px",
						lineHeight: "20px",
						color: "rgba(0, 0, 0, 0.60)",
					}}
				>
					<Highlight
						value={item.locationName + " - " + item.workcenterName}
						searchText={searchTerm}
					/>
				</Typography>
			</Box>
		);
	};

	return (
		<Box
			ref={boxRef}
			style={{
				width: "100%",
				paddingTop: 10,
			}}
		>
			{interestedWorkersList.length > 0 ? (
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-start",
						flexDirection: "column",
						height: "100%",
						width: "100%",
						padding: "8px",
					}}
				>
					{/* <TextField
						fullWidth
						placeholder='Search...'
						variant='outlined'
						onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
						sx={{ mb: 2 }} // Adjust margin as needed
					/> */}
					<TextField
						label='Search'
						fullWidth
						placeholder='Worker Name, Primary Job, Location, Workcenter'
						variant='filled'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
						size='small'
						InputProps={{
							endAdornment: searchTerm !== undefined &&
								searchTerm.length > 0 && (
									<CancelOutlinedIcon
										sx={{ cursor: "pointer", color: "#92918f" }}
										onClick={() => setSearchTerm("")}
									/>
								),
						}}
						sx={{
							"& .MuiFilledInput-underline:before": {
								borderBottom: "none",
							},
							"& .MuiFilledInput-underline:after": {
								borderBottom: "none",
							},
							//paddingTop: "4px",
							marginBottom: "16px",
							// marginLeft: "24px",
						}}
					/>
					<FixedSizeList
						width='100%'
						height={height - 48}
						itemCount={filteredWorkers.length}
						itemSize={88}
					>
						{({ index, style }) => (
							<Box style={style}>
								<ListItems item={filteredWorkers[index]} />
							</Box>
						)}
					</FixedSizeList>
				</Box>
			) : (
				<Box
					sx={{
						alignItems: "center",
						display: "flex",
						flexDirection: "column",
						mt: 10,
					}}
				>
					<PersonOffIcon sx={{ color: "#6c6d6e", fontSize: "40px" }} />
					No interested workers
				</Box>
			)}
		</Box>
	);
}

export default InterestedWorkers;
