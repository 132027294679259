/* eslint-disable simple-import-sort/imports */
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SkillCell from "../../../../../../design/skill-representaion-cell";
import Highlight from "../../../../../Highlight";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import CrossSkillLegends from "../../../../../cross-skilling/ui/cross-skill-header/ui/cross-skill-ledgends";
import React, { useEffect, useMemo, useState } from "react";
import { FixedSizeList } from "react-window";
import useTableHeight from "../../../../../../utils/use-table-height";
import PersonOffIcon from "@mui/icons-material/PersonOff";
// import { WorkerInsightModel } from "../../../slices/insight-report-types";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Searchbar from "../../../../../../design/search-bar";

function WorkersWithSkill({ workersWithSkill, allSkillLevels }: { workersWithSkill: any, allSkillLevels: any }) {
	const { height, boxRef } = useTableHeight();
	const [searchTerm, setSearchTerm] = useState("");
	const [expanded, setExpanded] = useState<string[]>([]);

	const qualifiedWorkersWithSkill = workersWithSkill.filter(
		(worker: any) =>
			allSkillLevels.find(
				(skillLevel: { level: number; isQualifiedAsSkilled: boolean }) =>
					worker.level === skillLevel.level && skillLevel.isQualifiedAsSkilled
			) !== undefined
	);

	// group workers based on skill level
	const workersGroupedBySkillLevel = useMemo(() => {
		const workersGrouped: any = {};
		qualifiedWorkersWithSkill.forEach((worker: any) => {
			if (workersGrouped[worker.level]) {
				workersGrouped[worker.level].push(worker);
			} else {
				workersGrouped[worker.level] = [worker];
			}
		});
		// initially filter workersGrouped by name in alphabetical order
		Object.keys(workersGrouped).forEach((level) => {
			workersGrouped[level] = workersGrouped[level].sort((a: any, b: any) =>
				(a.workerName).localeCompare(
					b.workerName
				)
			);
		});
		// filter workersGrouped to match search term
		if (searchTerm.length > 0) {
			Object.keys(workersGrouped).forEach((level) => {
				workersGrouped[level] = workersGrouped[level].filter(
					(worker: any) =>
						(worker.workerName)
							.toLowerCase()
							.includes(searchTerm.toLowerCase()) ||
						(worker.primaryJobName &&
							worker.primaryJobName
								.toLowerCase()
								.includes(searchTerm.toLowerCase())) ||
						worker.locationName.toLowerCase().includes(searchTerm.toLowerCase()) ||
						worker.workcenterName?.toLowerCase()
							.includes(searchTerm.toLowerCase())
				);
			});
		}
		return workersGrouped;
	}, [qualifiedWorkersWithSkill, searchTerm]);

	const onHandleAccordian = (level: string) => {
		if (expanded.includes(level)) {
			setExpanded(expanded.filter((item) => item !== level));
		} else {
			setExpanded([...expanded, level]);
		}
	};

	const filteredWorkers = useMemo(() => {
		if (searchTerm === "") {
			return qualifiedWorkersWithSkill;
		}
		return qualifiedWorkersWithSkill.filter(
			(worker: any) =>
				(worker.workerName)
					.toLowerCase()
					.includes(searchTerm.toLowerCase()) ||
				(worker.primaryJobName &&
					worker.primaryJobName
						.toLowerCase()
						.includes(searchTerm.toLowerCase())) ||
				worker.locationName.toLowerCase().includes(searchTerm.toLowerCase()) ||
				worker.workcenterName.toLowerCase().includes(searchTerm.toLowerCase())
		);
	}, [qualifiedWorkersWithSkill, searchTerm]);

	const ListItems: React.FC<{
		item: any;
	}> = ({ item }) => {
		return (
			<Box
				key={`worker-${item.workerId}`}
				sx={{
					display: "flex",
					alignItems: "flex-start",
					justifyContent: "center",
					flexDirection: "column",
					width: "100%",
					border: 1,
					borderLeftWidth: 0,
					borderRightWidth: 0,
					borderTopWidth: 0,
					borderBottomWidth: "1px",
					borderBottomColor: "#F0F0F3",
					// height: "120px",
					marginLeft: 4,
					marginBottom: 1,
				}}
			>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "16px",
						lineHeight: "24px",
						color: "rgba(0, 0, 0, 0.87)",
						fontWeight: 700,
					}}
				>
					<Highlight
						value={item.workerName}
						searchText={searchTerm}
					/>
				</Typography>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "14px",
						lineHeight: "24px",
						color: item.primaryJobName
							? "rgba(0, 0, 0, 0.87)"
							: "rgba(0, 0, 0, 0.60)",
					}}
				>
					{item.primaryJobName ? (
						<Highlight
							value={item.primaryJobName || "-"}
							searchText={searchTerm}
						/>
					) : (
						"-"
					)}
				</Typography>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "14px",
						lineHeight: "24px",
						color: "rgba(0, 0, 0, 0.60)",
					}}
				>
					<Highlight
						value={item.locationName + " - " + item.workcenterName}
						searchText={searchTerm}
					/>
				</Typography>
			</Box>
		);
	};

	return (
		<Box
			ref={boxRef}
			style={{
				width: "100%",
				paddingTop: 10,
			}}
		>
			<Box mr={2}>
				<Searchbar
					placeholder='Worker Name, Primary Job, Location, Workcenter'
					value={searchTerm}
					setSearch={setSearchTerm}
					debounce
				/>
			</Box>
			<Box
				sx={{
					maxHeight: height - 50,
					overflowY: "auto",
				}}
			>
				{workersWithSkill.length > 0 ? (
					Object.keys(workersGroupedBySkillLevel).map((level: any) =>
						workersGroupedBySkillLevel[level].length > 0 ? (
							<Box
								key={level}
								sx={{
									fontWeight: "bold",
									height: "100%",
									wordBreak: "break-word",
								}}
							>
								<Accordion
									sx={{ borderRadius: 0, boxShadow: "none" }}
									expanded={expanded.includes(level) || searchTerm.length > 0}
									onChange={() => onHandleAccordian(`${level}`)}
								>
									<AccordionSummary
										aria-controls={`${level}d-content`}
										id={`${level}-header`}
										expandIcon={
											<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
										}
										sx={{
											// "&.Mui-expanded": {
											// 	minHeight: 0,
											// 	marginTop: 10,
											// 	paddingRight: 10,
											// },
											// backgroundColor: "transparent",
											// height: 30,
											flexDirection: "row-reverse",
											"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
												transform: "rotate(90deg)",
											},
											"& .MuiAccordionSummary-expandIconWrapper": {
												marginRight: 1,
											},

											// paddingLeft: 10,
										}}
									>
										<Box
											sx={{ display: "flex", alignItems: "center", height: 10 }}
										>
											<CrossSkillLegends
												isSkillLevelShownInTableHeader={true}
												allSkillLevels={[allSkillLevels[level]]}
											/>
											<Typography
												style={{ fontSize: "14px" }}
												ml={1}
											>{`(${workersGroupedBySkillLevel[level].length})`}</Typography>
										</Box>
									</AccordionSummary>

									<AccordionDetails>
										<Box>
											{workersGroupedBySkillLevel[level].map((worker: any) => (
												<ListItems key={worker.workerId} item={worker} />
											))}
										</Box>
									</AccordionDetails>
								</Accordion>
							</Box>
						) : null
					)
				) : (
					<Box
						sx={{
							alignItems: "center",
							display: "flex",
							flexDirection: "column",
							mt: 10,
						}}
					>
						<PersonOffIcon sx={{ color: "#6c6d6e", fontSize: "40px" }} />
						No workers with skill
					</Box>
				)}
			</Box>
		</Box>
	);
}

export default WorkersWithSkill;
