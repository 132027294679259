import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HistoryIcon from "@mui/icons-material/History";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { VariableSizeList as _VariableSizeList } from "react-window";
import { VariableSizeListProps } from "react-window";

import { parseCalcExpression, parseNumberFromPx } from "../../../utils/helpers";
import {APPLIED_FILTERS_HEIGHT} from "../constants";
import { AppliedFilter, FilterContext } from "../filter-context";
import FilterSearch from "./filter-search";
import {Filter} from "./types";
import { ListForFilter } from "./ui/list-for-filter";

const VariableSizeList =
  _VariableSizeList as unknown as React.ComponentType<VariableSizeListProps>;

const FILTER_WIDTH = 282;
const BACKGROUND_COLOR = "#fff";
const EMPTY_SPACE_COLOR = "#fff";
const BORDER_COLOR = "rgba(0,0,0,0.12)";
const FONT_COLOR = "rgba(0,0,0,0.87)";
const MIN_CONTENT_HEIGHT = 160;
const SEARCH_HEIGHT = 48;
const FILTER_HEIGHT = 48;
const ACTION_BAR_HEIGHT = APPLIED_FILTERS_HEIGHT;
const SEARCH_BAR_REQUIRED_COUNT = 10;
const compareCssValues = (value1: string, value2: string): string => {
  // Helper function to evaluate calc expressions
  const evaluateCalc = (calcValue: string): number => {
    // Create a temporary element
    const tempEl = document.createElement("div");
    // Set its position to fixed, and off-screen
    tempEl.style.position = "fixed";
    tempEl.style.top = "100vh"; // Ensures that 100vh will be equal to window.innerHeight
    tempEl.style.height = calcValue;

    // Append to the body to force layout calculation
    document.body.appendChild(tempEl);
    // Get the computed height
    const computedHeight = window.getComputedStyle(tempEl).height;
    // Remove the element
    document.body.removeChild(tempEl);

    // Return the numeric value
    return parseFloat(computedHeight);
  };

  // Helper function to get the numeric value of a CSS value
  const getNumericValue = (cssValue: string): number => {
    if (cssValue.startsWith("calc")) {
      return evaluateCalc(cssValue);
    }
    return parseFloat(cssValue);
  };

  const numericValue1 = getNumericValue(value1);
  const numericValue2 = getNumericValue(value2);

  return numericValue1 > numericValue2 ? value1 : value2;
};

interface FilterAccordionProps {
  filters: Filter[];
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  defaultAppliedFilters: AppliedFilter[];
  minFilterAccordionHeight: number;
}

const FilterAccordion: React.FC<FilterAccordionProps> = ({
  filters,
  setFilters,
  defaultAppliedFilters,
  minFilterAccordionHeight = 480,
}) => {
  const [initialized, setInitialized] = useState(false);
  const filterContext = useContext(FilterContext);
  const accordionRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<string | number>("auto");
  const [expandedContentHeight, setExpandedContentHeight] = useState<string>(
    `${MIN_CONTENT_HEIGHT}px`
  );

  if (!filterContext) {
    throw new Error("FilterAccordion must be used within a FilterProvider");
  }

  const {
    appliedFilters,
    setAppliedFilters,
    setFilterAccordionHeight,
    searchedValue,
    setSearchedValue,
  } = filterContext;
  const [expanded, setExpanded] = useState<string | false>(false);

  useEffect(() => {
    const updateHeight = () => {
      try {
        if (accordionRef.current) {
          const rect = accordionRef.current.getBoundingClientRect();
          const viewPortHeight = parseCalcExpression("calc(100vh)");


          let minHeight =
            filters.length * FILTER_HEIGHT +
            SEARCH_HEIGHT +
            MIN_CONTENT_HEIGHT +
            ACTION_BAR_HEIGHT;
          minHeight = minHeight > minFilterAccordionHeight ? minHeight : minFilterAccordionHeight;
          let filterHeight = viewPortHeight - rect.top - 24;
          filterHeight = filterHeight > minHeight ? filterHeight : minHeight;
          const filterAccordionContentHeight =
              filterHeight -
              SEARCH_HEIGHT -
              ACTION_BAR_HEIGHT -
              filters.length * FILTER_HEIGHT;
          const newExpandedContentHeight =
            filterAccordionContentHeight > MIN_CONTENT_HEIGHT
              ? `${filterAccordionContentHeight}px`
              : `${MIN_CONTENT_HEIGHT}px`;
          setHeight(`${filterHeight}px`);
          setFilterAccordionHeight(filterHeight);
          setExpandedContentHeight(newExpandedContentHeight);
        }
      } catch (e) {
        // Do nothing
      }
    };

    window.addEventListener("resize", updateHeight);
    updateHeight(); // Initial call

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, minFilterAccordionHeight]);

  useEffect(() => {
    if (defaultAppliedFilters.length !== 0 && !initialized) {
      setInitialized(true);
      setAppliedFilters(defaultAppliedFilters);
    }
  }, [defaultAppliedFilters, setAppliedFilters, initialized]);

  useEffect(() => {
    try {
      // Reset all filters data to reduced value when search value is empty
      if (searchedValue === "") {
        const newFilters = [...filters];
        newFilters.forEach((filter) => {
          filter.searchedData = filter.reducedData;
        });
        setFilters(newFilters);
      }
    } catch (e) {
      // Do nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValue]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      setSearchedValue("");
    };

  const handleAddFilter = (type: string, filterData: any) => {
    const newFilter = {
      type,
      timeAdded: Date.now(),
      data: [
        {
          id: filterData.id,
          timeAdded: Date.now(),
          name: filterData.name,
        },
      ],
    };
    setAppliedFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      const filterIndex = newFilters.findIndex(
        (filter) => filter.type === type
      );
      if (filterIndex > -1) {
        newFilters[filterIndex].data.push(newFilter.data[0]);
      } else {
        newFilters.push(newFilter);
      }
      return newFilters;
    });
  };

  const handleRemoveFilter = (type: string, id: number) => {
    setAppliedFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      const filterIndex = newFilters.findIndex(
        (filter) => filter.type === type
      );
      if (filterIndex > -1) {
        newFilters[filterIndex].data = newFilters[filterIndex].data.filter(
          (data) => parseInt(data.id) !== id
        );
        if (newFilters[filterIndex].data.length === 0) {
          // Remove filter if no data is present
          newFilters.splice(filterIndex, 1);
          return newFilters;
        } else {
          return newFilters;
        }
      } else {
        return prevFilters;
      }
    });
  };

  const handleResetFilters = () => {
    setAppliedFilters([]);
  };

  const handleSearch = (text: string, id: number) => {
    try {
      const newFilters = [...filters];
      const indexOfFilter = newFilters.findIndex((filter) => filter.id === id);
      if (indexOfFilter > -1) {
        const selectedFilter = newFilters[indexOfFilter];
        if (selectedFilter) {
          const searchedData = selectedFilter.reducedData.filter((data) =>
            data.name.toLowerCase().includes(text.toLowerCase())
          );
          if (searchedData.length > 0) {
            selectedFilter.searchedData = searchedData;
          } else {
            selectedFilter.searchedData = selectedFilter.reducedData;
          }
        }
        newFilters[indexOfFilter] = selectedFilter;
      }
      setFilters(newFilters);
    } catch (e) {
      // Do nothing
    }
  };

  return (
    <Box
      ref={accordionRef}
      sx={{
        width: `${FILTER_WIDTH}px`,
        height: height,
        borderRadius: "0 0 0 15px",
        border: `1px solid ${BORDER_COLOR}`,
        borderTop: "none",
        borderBottom: "none",
        borderLeft: "none",
        backgroundColor: EMPTY_SPACE_COLOR,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Button
        startIcon={<HistoryIcon sx={{ fontSize: "18px", marginLeft: "4px" }} />}
        onClick={handleResetFilters}
        fullWidth={true}
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          textAlign: "left",
          padding: "12px 24px",
          borderBottom: `1px solid ${BORDER_COLOR}`,
          backgroundColor: BACKGROUND_COLOR,
          fontSize: "13px",
          lineHeight: "22px",
          justifyContent: "flex-start",
          fontFamily: "Roboto",
          height: `${APPLIED_FILTERS_HEIGHT}px`,
          "&:hover": {
            backgroundColor: "#F9F9F9",
          },
          borderRadius: 0,
        }}
        variant="text"
      >
        Clear
      </Button>
      {filters.map((filter, index) => (
        <Accordion
          key={`${filter.id}-${index}`}
          expanded={expanded === `panel${index}`}
          disableGutters
          onChange={handleChange(`panel${index}`)}
          sx={{
            boxShadow: "none",
            border: 1,
            borderLeft: "none",
            borderRight: "none",
            borderBottom:
              index === filters.length - 1 &&
              expanded !== `panel${filters.length - 1}`
                ? `1px solid ${BORDER_COLOR}`
                : "none",
            borderTop:
              expanded === `panel${index - 1}`
                ? "none"
                : expanded === `panel${index}` && index !== 0
                ? `1px solid ${BORDER_COLOR}`
                : "none",

            "&.MuiAccordion-root": {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 24px",
              "&:hover": {
                backgroundColor: "#F9F9F9",
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "14px",
                lineHeight: "20px",
                maxWidth: "209px",
                fontWeight: 400,
                color: FONT_COLOR,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {`${filter.name}${
                filter.reducedData.length > 0
                  ? ` (${filter.reducedData.length})`
                  : ""
              }`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              margin: "0px -24px",
              padding: "0px",
              borderBottomLeftRadius:
                index === filters.length - 1 &&
                expanded !== `panel${filters.length - 1}`
                  ? 15
                  : 0,
            }}
          >
            {filter.reducedData.length > SEARCH_BAR_REQUIRED_COUNT && (
              <Box
                sx={{
                  width: `${FILTER_WIDTH}px`,
                  margin: "0px 24px",
                  padding: "0px 24px",
                  height: `${SEARCH_HEIGHT}px`,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    height: "48px",
                    flex: 1,
                  }}
                >
                  <FilterSearch
                    filter={filter}
                    handleSearch={handleSearch}
                    searchedValue={searchedValue}
                    setSearchedValue={setSearchedValue}
                  />
                </Box>
              </Box>
            )}
            <Box
              sx={{
                width: `${FILTER_WIDTH}px`,
                margin: "0px 24px",
                padding: "0px",
                boxShadow:
                  "inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC",
                height: `${
                  filter.reducedData.length > SEARCH_BAR_REQUIRED_COUNT
                    ? parseNumberFromPx(expandedContentHeight)
                    : parseNumberFromPx(expandedContentHeight) + SEARCH_HEIGHT
                }px`,
                borderBottomLeftRadius: index === filters.length - 1 ? 15 : 0,
                overflowX: "hidden",
              }}
            >
              <VariableSizeList
                height={
                  filter.reducedData.length > SEARCH_BAR_REQUIRED_COUNT
                    ? parseNumberFromPx(expandedContentHeight)
                    : parseNumberFromPx(expandedContentHeight) + SEARCH_HEIGHT
                }
                width={"100%"}
                itemSize={(index) => {
                  if (index === 0 || index === filter.searchedData.length - 1) {
                    return 42; // Height for the first and last rows
                  }
                  return 30;
                }}
                style={{
                  overflowX: "hidden",
                }}
                itemCount={filter.searchedData.length}
                overscanCount={5}
                itemData={{
                  items: filter.searchedData,
                  searchedValue,
                  isChecked: (id: number) => {
                    const selectedFilter = appliedFilters.find(appliedFilter => appliedFilter.type === filter.name);
                    return selectedFilter?.data ? selectedFilter?.data.filter((data) => parseInt(data.id) === id).length > 0 : false;
                  },
                  listWidth: FILTER_WIDTH,
                  applyFilter: (id: number, checked: boolean) => {
                    if (checked) {
                      handleAddFilter(
                        filter.name,
                        filter.data.find((d) => d.id === id)
                      );
                    } else {
                      handleRemoveFilter(filter.name, id);
                    }
                  },
                }}
              >
                {ListForFilter}
              </VariableSizeList>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FilterAccordion;
